import dayjs from 'dayjs'
import {
  parseAsArrayOf,
  parseAsBoolean,
  parseAsInteger,
  parseAsJson,
  parseAsString,
  parseAsStringLiteral
} from 'nuqs/server'
import { startTransition } from 'react'
import { z } from 'zod'
import {
  box,
  defaultCountry,
  pageSize,
  supportedCountries,
  views
} from '~/data/enums'
import { today, tomorrow } from '~/utils/date'

export const productSelectionSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    counts: z.number(),
    price: z.object({ amount: z.number() })
  })
)
export const selectedRoomSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    // Products of types services or addons linked to the property
    type: z.union([z.literal('service'), z.literal('addon')]).optional(),
    counts: z.number(),
    price: z.object({ amount: z.number() })
  })
)

export const productSelectionsSchema = z.array(
  z.object({
    id: z.string(),
    counts: z.number(),
    name: z.string(),
    guests: z.number(),
    addons: productSelectionSchema,
    products: productSelectionSchema
  })
)

export const countrySchema = z.union([
  z.literal('burundi'),
  z.literal('cameroon')
])

/**
 * Query String Parsers
 */
export const queryStringParsers = {
  counts: parseAsInteger.withDefault(1),
  box: parseAsStringLiteral(box),
  mapProperty: parseAsString,
  map: parseAsJson(z.object({ property: z.string(), zoom: z.number() }).parse),
  image: parseAsInteger.withDefault(1),
  email: parseAsString,

  page: parseAsInteger
    .withDefault(0)
    .withOptions({ startTransition, shallow: false }),
  size: parseAsInteger
    .withDefault(pageSize)
    .withOptions({ startTransition, shallow: false }),

  reservationDetailsDrawer: parseAsJson(
    z.object({ isOpened: z.boolean() }).parse
  ).withDefault({ isOpened: false }),
  country: parseAsStringLiteral(supportedCountries)
    .withDefault(defaultCountry)
    .withOptions({ startTransition, shallow: false }),

  checkIn: parseAsString
    .withDefault(dayjs(today).format('YYYY-MM-DD'))
    .withOptions({ startTransition, shallow: false }),

  checkOut: parseAsString
    .withDefault(dayjs(tomorrow).format('YYYY-MM-DD'))
    .withOptions({ startTransition, shallow: false }),

  view: parseAsStringLiteral(views)
    .withDefault('list')
    .withOptions({ startTransition, shallow: false }),
  isCompare: parseAsBoolean.withDefault(false),
  reservation: parseAsJson(
    z.object({
      processing: z.boolean(),
      status: z.union([
        z.literal('failure'),
        z.literal('success'),
        z.literal('idle')
      ])
    }).parse
  ).withDefault({ processing: false, status: 'idle' }),
  propertiesToCompare: parseAsArrayOf(parseAsString).withDefault([]),
  products: parseAsArrayOf(parseAsString).withDefault([]),
  selectedRooms: parseAsJson(productSelectionSchema.parse),
  selectedProducts: parseAsJson(productSelectionSchema.parse),
  selectedAddons: parseAsJson(productSelectionSchema.parse),
  isWidget: parseAsBoolean.withDefault(false),
  futureSelectedProducts: parseAsJson(
    productSelectionsSchema.parse
  ).withDefault([]),

  guests: parseAsInteger
    .withDefault(1)
    .withOptions({ startTransition, shallow: false }),

  amenities: parseAsArrayOf(parseAsString)
    .withDefault([])
    .withOptions({ startTransition, shallow: false }),

  lifestyle: parseAsArrayOf(parseAsString)
    .withDefault([])
    .withOptions({ startTransition, shallow: false }),

  location: parseAsArrayOf(parseAsString)
    .withDefault([])
    .withOptions({ startTransition, shallow: false })
}
