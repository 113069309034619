import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/home/runner/work/dwelinn/dwelinn/node_modules/@mantine/core/esm/components/Container/Container.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["HeroImages"] */ "/home/runner/work/dwelinn/dwelinn/src/app/[country]/(landing)/components/hero_images.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeFooter"] */ "/home/runner/work/dwelinn/dwelinn/src/app/[country]/(landing)/components/home_footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeHeader"] */ "/home/runner/work/dwelinn/dwelinn/src/app/[country]/(landing)/components/home_header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntroText"] */ "/home/runner/work/dwelinn/dwelinn/src/app/[country]/(landing)/components/intro_text.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SlidingBox"] */ "/home/runner/work/dwelinn/dwelinn/src/app/[country]/(landing)/components/sliding_box.tsx");
