'use client'

import type { MantineThemeComponents } from '@mantine/core'
import { ButtonExtension } from './button'
import { DividerExtension } from './divider'
import { InputExtension } from './input'
import { PopoverExtension } from './popover'
import { RadioExtension, RadioGroupExtension } from './radio'
import { SelectExtension } from './select'
import { TextInputExtension } from './text_input'

export const components: MantineThemeComponents = {
  Button: ButtonExtension,
  Input: InputExtension,
  TextInput: TextInputExtension,
  Select: SelectExtension,
  Radio: RadioExtension,
  RadioGroup: RadioGroupExtension,
  Divider: DividerExtension,
  Popover: PopoverExtension
}
