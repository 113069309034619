'use client'

import { useParams } from 'next/navigation'
import { countries } from '~/data/countries'
import { defaultCountry, SupportedCountries } from '~/data/enums'
import { useQS } from '~/hooks/use_qs'

export function IntroText() {
  const [, setQS] = useQS()
  const params = useParams<{ country: SupportedCountries }>()

  const country = countries[params.country || defaultCountry]
  return (
    <div className="flex flex-col gap-6">
      <h1 className="m-0 text-center leading-none text-white md:text-left">
        <span className="block text-lg font-semibold">Welcome to</span>

        <span className="text-pretty bg-transparent bg-[url('/images/african_pattern.jpg')] bg-clip-text bg-top bg-repeat text-5xl font-extrabold text-transparent sm:text-6xl md:text-8xl">
          {country.name}
        </span>
      </h1>
      <p className="text-center text-2xl leading-normal md:text-left">
        <span>Find the best accomodation deals in the land of&nbsp;</span>
        <br />{' '}
        <button
          name="box"
          value="nyama-choma"
          className="text-teal-500 hover:cursor-pointer"
          onClick={() => setQS({ box: 'dish' })}
        >
          {country.bestKnown.dish.name}
        </button>{' '}
        <span>and</span>{' '}
        <button
          name="box"
          value="tusker-beer"
          className="text-teal-500 hover:cursor-pointer"
          onClick={() => setQS({ box: 'drink' })}
        >
          {country.bestKnown.drink.name}
        </button>
      </p>
    </div>
  )
}
